/* Maps */

#map {
	padding: 10px;
	padding-bottom: 30px;
}

.map-col-element {
	margin-top: 20px;
	padding: 0px;

	display: flex;
    align-items: center;
}

.google-maps {
    position: relative;
    min-height: 40vh;
    overflow: hidden;
}

.google-maps iframe {
    width: 100%;
    height: 100%;
}

.maps-info {
	display: inline;
	width: 100%;
	padding-right: 20px;
	padding-left: 20px;
}