/* Base styling for the navbar */

#mainNav {
  /* Small screen styling - screen sizes smaller than $min-screen-width */
  box-shadow: 0 1px 0px rgba($theme-primary, .9);
  background-color: $white;
  @include transition-all;
  .navbar-brand {
    font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    text-transform: uppercase;
    color: $theme-primary;
    @media (max-width: $xs-screen-width) {
      font-size: 1em;
    }
  }
  .navbar-nav {
    .nav-item {
      .nav-link {
        /* Tweak to enforce the menu buttons to instantly disappear */
        transition: 0s;
        color: $theme-dark;
        font-family: $font-family-sans-serif;
        font-weight: $font-weight-bold;
        font-size: 0.9rem;
        padding: 0.75rem 0;
        text-transform: uppercase;
        &:hover,
        &:active {
          color: $theme-primary;
        }
        &.active {
          color: $theme-primary !important;
        }
      }
    }
  }

  /* Base styling for the navbar - screen sizes larger than $min-screen-width */
  @media (min-width: $min-screen-width) {
    box-shadow: none;
    background-color: transparent;

    select,
    .navbar-brand {
      color: rgba($white, .7);
      &:hover {
        color: $white;
      }
    }

    .navbar-nav {
      .nav-item {
        .nav-link {
          color: rgba($white, .7);
          padding: 0 1rem;
          &:hover {
            color: $white;
          }
        }
        &:last-child {
          .nav-link {
            padding-right: 0;
          }
        }
      }
    }
    /* Navbar styling applied when the page is scrolled and on large screens only */
    &.navbar-scrolled {
      box-shadow: 0 1px 0px rgba($theme-primary, .9);
      background-color: $white;

      select {
        color: $theme-dark;
        &:hover {
          color: $theme-primary;
        }
      }

      .navbar-brand {
        color: $theme-primary;
        &:hover {
          color: darken($theme-primary, 10%);
        }
      }

      .navbar-nav {
        .nav-item {
          .nav-link {
            color: $theme-dark;
            &:hover {
              color: $theme-primary;
            }
          }
        }
      }
    }
  }
}

/* Position of the close button (top right corner). Hidden by default*/
#closeSlideshowButton {
  visibility: hidden;
  position: absolute;
  right: 20px;
  font-size: 40px;
  text-decoration: none;
  /* Center the button vertically in div */
  top: 50%;
  transform: translateY(-50%);
  /* Tweak the close button design to be perfectly verticaly aligned with the title */
  padding-bottom: 8px;

  /* Tweak to enforce the close button to instantly disappear */
  transition: 0s;
}
