@charset "utf-8";

/* Initial variables and override of default bootstrap static values
 *
 * Because SASS cannot load and compile online resources but only local files
 * we need to hard-code some pre-compiled values.
 */
$theme-primary: #F05F40;
$theme-dark: #222;
$theme-dark-highlight: rgba(48, 6, 6, .5);
$white: #fff;
$min-screen-width: 992px;
$xs-screen-width: 400px; /* Very small devices (~iPhone 5). */

/* Import partials from `sass_dir` (defaults to `_sass`) */
@import
        "mixins",
        "services",
        "timeline",
        "switcher",
        "slideshow",
        "img-lazy",
        "navbar",
        "header",
        "map",
        "gallery",
        "contact",
        "booking",
        "booklet",
        "404",
        "base"
;