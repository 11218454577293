/* Slideshow with background overlay */
.overlay {
  z-index: 1029; /* Sit right behind the nav bar which is 1030 by default as defined in the bootstrap.min.css */
  height: 100%;
  width: 100%;
  position: fixed;
  left: 0;
  top: 0;
  visibility: hidden;
  background-color: black;
  overflow-x: hidden; /* Disable horizontal scroll */
}

/* Horizontal Carousel */

.carousel {
  /* Center the carousel vertically */
  top: 50%;
  transform: translateY(-50%);
}

.carousel-cell {
  width: 60%;
  margin-right: 10px;
  margin-left: 10px;
}

.carousel-cell-image {
  display: block;
  max-height: 100%;
  margin: 0 auto;
  max-width: 100%;
  opacity: 1;
}

.flickity-page-dots .dot {
  /* Change the color of the active dots to hover color of the navigation elements */
  background: hsla(0, 0%, 100%, .75);
}

/* You can enable and disable Flickity with CSS.
  The `watchCSS` option watches the content of :after of the carousel element.
  Flickity is enabled if :after content is 'flickity'.*/
.carousel:after {
  /* Enable flickity by default */
  content: 'flickity';
  display: none;
}

/* Disable Flickity for large devices and configure CSS for vertical carousel */
@media screen and ( max-width: 1000px ) {

  .carousel:after {
    content: '';
  }

  .carousel {
    top: 0%;
    transform: translateY(0%);
    /* Top margin corresponding to the navbar height + cell top margin */
    margin-top: 60px;
  }

  .carousel-cell {
    width: 100%;
    margin-right: 0px;
    margin-left: 0px;
    margin-top: 10px;

    height: 0;
    position: relative;
    /* Required padding hack to force the lazyload plugin to load the images only when they enter the viewport. */
    /* https://www.andyshora.com/css-image-container-padding-hack.html */
    /* https://github.com/verlok/lazyload#vertical-padding-trick */
    /* Padding Bottom = (Image Height / Image Width) * 100 */
    padding-bottom: 75%;
  }

  .carousel-cell-image {
    width: 100%;
    height: auto;
    position: absolute;
  }
}
