html,
body {
    height: 100%;
    width: 100%;
}

body {
    @include serif-font;
}

hr {
    border-color: $theme-primary;
    border-width: 3px;
    max-width: 50px;
}

hr.light {
    border-color: white;
}

a {
    @include transition-all;
    color: $theme-primary;
    &:hover,
    &:focus {
        color: darken($theme-primary, 10%);
    }
}

h1,
h2,
h3,
h4,
h5,
h6 {
    @include sans-serif-font;
}

p {
    font-size: 16px;
    line-height: 1.5;
    margin-bottom: 20px;
}

.bg-theme-primary {
    background-color: $theme-primary;
}

.bg-dark {
    background-color: $theme-dark;
    color: white;
}

.text-faded {
    color: rgba(white, .7);
}

section {
    padding: 75px 0;
}

section h2.section-heading {
}

section h3.section-subheading {
    margin-bottom: 75px;
    text-transform: none;
    font-size: 16px;
    font-style: italic;
    font-weight: 400;
}

footer {
    padding: 10px;
}

.no-padding {
    padding: 0;
}

/* Bootstrap Overrides */
.icon-theme-primary {
    color: $theme-primary;
}

.no-gutter > [class*='col-'] {
    padding-right:0;
    padding-left:0;
}

/* Button Styles */
.btn-secondary-action {
    @include button-variant($theme-dark, white, white);
}

.btn-primary-action {
    @include button-variant(white, $theme-primary, $theme-primary);
}

.btn {
    @include sans-serif-font;
    border: none;
    border-radius: 300px;
    font-weight: 700;
    text-transform: uppercase;

    box-shadow: 4px 4px 15px rgba(0, 0, 0, 0.3); /* Offset-x, Offset-y, Blur-radius, Color */
}

.btn-xl {
    padding: 15px 30px;

    /* Enforce a minimal width for btn-xl on small devices */
    /* This makes sure the text will stay on 1 line only */
    @media (max-width: $min-screen-width) {
        min-width: 290px;
    }
}

.btn-sm {
    /* Make sure the design fits perfectly with an icon within */
    padding-right: 10px;
    padding-left: 10px;
    i {
        margin-right: 8px;
    }

    /* Base style for small screen. */
    /* Fixed minimum width to enforce one line text on small devices */
    @media (max-width: $min-screen-width) {
        min-width: 230px;
    }
}

/* Extras */
/* -- Highlight Color Customization */
::-moz-selection {
    color: white;
    text-shadow: none;
    background: $theme-dark;
}

::selection {
    color: white;
    text-shadow: none;
    background: $theme-dark;
}

img::selection {
    color: white;
    background: transparent;
}

img::-moz-selection {
    color: white;
    background: transparent;
}

body {
    -webkit-tap-highlight-color: $theme-dark;
}

