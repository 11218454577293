/* Fade in lazy laoded images */

/* Gallery Images (smaller size) */

.img-lazy {
  display: block;
  opacity: 0;
}

.img-lazy:not(.initial) {
  transition: opacity 1s;
}

.img-lazy.initial,
.img-lazy.loaded,
.img-lazy.error {
  opacity: 1;
}

.img-lazy:not([src]) {
  visibility: hidden;
}
