/* Homepage Header */

header {
    position: relative;
    width: 100%;
    min-height: auto;
    @include background-cover;
    background-position: center;
    background-image: url('../img/gallery/large/fr/canape-comfortable-location-vacances-plage.jpeg');
    text-align: center;
    color: white;
    .header-content {
        position: relative;
        text-align: center;
        padding: 100px 15px 100px;
        width: 100%;
        .header-content-inner {
            h1 {
                font-weight: 700;
                text-transform: uppercase;
                margin-top: 0;
                margin-bottom: 0;
                background-color: $theme-dark-highlight;
            }
            hr {
                margin: 30px auto;
            }
            p {
                font-weight: 300;
                font-size: 16px;
                margin-bottom: 50px;
            }
        }
    }

    .header-bottom-content {
        /* Position: bottom & horizontal center */
        /* Define CSS on small screens */
        position: inherit;
        bottom: 20px;
        display: inline-flex;
        margin: auto;
        .col-sm {
            margin-top: 20px;
        }
    }

    @media (min-width: $min-screen-width) {
        min-height: 100%;
        .header-content {
            position: absolute;
            top: 50%;
            -webkit-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            transform: translateY(-50%);
            padding: 0 50px;
            .header-content-inner {
                max-width: 1000px;
                margin-left: auto;
                margin-right: auto;
                p {
                    font-size: 18px;
                    max-width: 80%;
                    margin-left: auto;
                    margin-right: auto;
                }
            }
        }

        /* Override design for large screens */
        .header-bottom-content {
            position: absolute;
            transform: translateX(-50%);
        }
    }
}