/* booklet */

#booklet {
    padding: 10px;
    padding-bottom: 30px;
}

.booklet-col-element {
    margin-top: 20px;
    padding: 0px;

    display: flex;
    align-items: center;

    a {
        margin: 10px;
        padding-left: 15px;
        padding-right: 15px;

        i {
            padding-right: 10px;
        }
    }
}

.youtube-video {
    position: relative;
    min-height: 30vh;
    min-width: 40vh;
    max-height: 1080px;
    overflow: hidden;

    @media (max-width: $min-screen-width) {
        min-height: 20vh;
        min-width: 20vh;
    }
}

.youtube-video iframe {
    width: 100%;
    height: 100%;
}

.booklet-info {
    display: inline;
    width: 100%;
    padding-right: 20px;
    padding-left: 20px;
}