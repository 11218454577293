/* CSS Mixins */

@mixin transition-all()
{
	-webkit-transition: all 0.35s;
	-moz-transition: all 0.35s;
	transition: all 0.35s;
}

@mixin background-cover()
{
	-webkit-background-size: cover;
	-moz-background-size: cover;
	background-size: cover;
	-o-background-size: cover;
}

@mixin button-variant($color, $background, $border)
{
	color: $color;
	background-color: $background;
	border-color: $border;
	@include transition-all;

	&:hover,
	&.hover,
	&:focus,
	&.focus,
	&:active,
	&.active {
		color: $color;
		background-color: darken($background, 5%);
		border-color: darken($border, 7%);
	}
}

$font-family-sans-serif: "Merriweather Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;

$font-family-serif: "Merriweather", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", 'Noto Color Emoji' !default;

$font-weight-bold: 700;

@mixin sans-serif-font()
{
	font-family: $font-family-sans-serif;
}

@mixin serif-font()
{
	font-family: $font-family-serif;
}