/* Booking */

/* Overwrite base style with #id */
#booking {

	h5 {
		/* Margin around the coming soon*/
		margin-top: 35px;
		margin-bottom: 0px;
	}

	.about {
		padding: 0;
		margin: 20px 0 20px 0;
	}
}

.booking-partner {
	height: 100px;

	/* Vertically and horizontally align the content*/
	display: flex;
	justify-content: center;
	align-items: center;

	img {
		max-height: 70%;
	}

	/* Border */
	border-radius: 10px;
	border: 4px double #B0B0B0; /* Dark Gray */

	/* Hover transition */
	transition: all 0.5s;
	cursor: pointer;

	/* Margin between the elements*/
	margin: 20px;
}

.booking-partner:hover {
	background-color: #CDCDCD; /* Mid Gray*/
}

.reviews {
	margin-top: 20px;
}