/* Services */

.service-box-row {
    margin-left: auto;
    margin-right: auto;
}

.service-box-container {
    padding: 25px 10px 25px 10px;

    @media (max-width: $min-screen-width) {
        padding: 0;
    }
}

.service-box {
    max-width: 400px;
    margin: 50px auto 0;
    @media (min-width: $min-screen-width) {
        margin: 20px auto 0;
    }
    p {
        margin-bottom: 0;
    }
    i {
        margin-bottom: .7rem;
    }
}
