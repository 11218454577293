/* Language Switcher */

select {
	/* Base styling for small screens */
	padding: 0.75rem 0;
	border: none;

	/* Text and font configuration */
	text-transform: uppercase;
	/*color: $theme-dark;*/
	font-family: $font-family-sans-serif;
    font-weight: $font-weight-bold;
    font-size: 0.9rem;

    /* Hide/Remove the default arrows of the select element */
	-webkit-appearance: none;
	-moz-appearance: none;
	appearance: none;
	background: none;


	@media (min-width: $min-screen-width) {
		/* Base styling for large screens - overwrite style for small screens */
		margin-left: 15px;
		padding: 0;
	}
}


/* CAUTION: Internet Explorer hackery ahead */

select::-ms-expand {
	/* Remove default arrow in Internet Explorer 10 and 11 */
	display: none;
}
